import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import html2canvas from "html2canvas";
import { Snackbar, Alert, CircularProgress } from "@mui/material";
import { isUserLoggedIn } from "../../utils/authUtils";
import { resolveLinksAndExtractASINs } from "../../utils/ApiHelper";
import ProductPost from "../../components/ProductPost";

const randomEmoji = () => {
    const emojis = ["✨", "🔥", "🌟", "💥", "🚀", "🎉"];
    return emojis[Math.floor(Math.random() * emojis.length)];
};

const CollagePage = () => {
    const [isAsinMode, setIsAsinMode] = useState(true);
    const [inputValue, setInputValue] = useState("");
    const [productImages, setProductImages] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const collageRef = useRef();
    const [isUserLoggedInState, setIsUserLoggedInState] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" | "error" | "warning" | "info"


    useEffect(() => {
        if (!isUserLoggedIn()) {
            setIsUserLoggedInState(false);
            return;
        }
    }, []);

    const handleToggle = () => {
        setIsAsinMode((prev) => !prev);
        setInputValue("");
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    // const fetchProductDetails = async () => {
    //     if (!inputValue.trim()) return;

    //     let itemIds = [];
    //     setIsLoading(true);
    //     try {
    //         if (isAsinMode) {
    //             itemIds = inputValue.split(",").map((id) => id.trim());
    //         } else {
    //             itemIds = await resolveLinksAndExtractASINs(inputValue);
    //         }

    //         if (itemIds.length === 0) {
    //             throw new Error("Invalid product links or IDs.");
    //         }

    //         const payload = { itemIds };
    //         const response = await axios.post("https://dealcraft-api.vercel.app/makepost", payload, {
    //             headers: { "Content-Type": "application/json" },
    //         });

    //         const product = response.data.ItemsResult.Items[0];
    //         const images = [
    //             product.Images.Primary.Large.URL,
    //             ...product.Images.Variants.map((v) => v.Large.URL),
    //         ];

    //         setProductImages(images);
    //         setSelectedImages(images.slice(0, 9));
    //         setProduct(product);
    //     } catch (error) {
    //         console.error("Error fetching product details:", error);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    // Get grid class based on selectedImages length

    const fetchProductDetails = async () => {
        if (!inputValue.trim()) return;

        let itemIds = [];
        setIsLoading(true);

        const tryFetchingDetails = async () => {
            try {
                if (isAsinMode) {
                    itemIds = inputValue.split(",").map((id) => id.trim());
                } else {
                    itemIds = await resolveLinksAndExtractASINs(inputValue);
                }

                if (itemIds.length === 0) {
                    throw new Error("Invalid product links or IDs.");
                }

                const payload = { itemIds };
                const response = await axios.post("https://dealcraft-api.vercel.app/makepost", payload, {
                    headers: { "Content-Type": "application/json" },
                });

                const product = response.data.ItemsResult.Items[0];
                if (!product) {
                    throw new Error("No product data returned.");
                }

                const images = [
                    product.Images.Primary.Large.URL,
                    ...product.Images.Variants.map((v) => v.Large.URL),
                ];

                setProductImages(images);
                setSelectedImages(images.slice(0, 9));
                setProduct(product);

                // Show success snackbar message
                setSnackbarMessage("Product details fetched successfully!");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                return true; // Successfully fetched
            } catch (error) {
                console.error("Error fetching product details:", error);
                return false; // Failed to fetch
            }
        };

        const success = await tryFetchingDetails();
        if (!success) {
            // Retry once
            const retrySuccess = await tryFetchingDetails();
            if (!retrySuccess) {
                // Show error snackbar message on second failure
                setSnackbarMessage("Sorry, unable to fetch product details. Please check your input or try again.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        }

        setIsLoading(false);
    };

    const getGridClass = () => {
        const layouts = {
            1: "grid-cols-1",
            2: "grid-cols-2",
            3: "grid-cols-3",
            4: "grid-cols-2",
            5: "grid-cols-3",
            6: "grid-cols-3",
            7: "grid-cols-4",
            8: "grid-cols-4",
            9: "grid-cols-3",
        };
        return layouts[selectedImages.length] || "grid-cols-3";
    };

    const createCollage = async () => {
        if (collageRef.current) {
            const canvas = await html2canvas(collageRef.current, {
                useCORS: true,
                scale: 3, // Increase the rendering scale
            });
            return canvas.toDataURL("image/png");
        }
    };


    const downloadCollage = async () => {
        const dataUrl = await createCollage();
        if (dataUrl) {
            const link = document.createElement("a");
            link.href = dataUrl;
            link.download = "dealskraft_Collage.png";
            link.click();
        }
    };

    const copyCollage = async () => {
        const dataUrl = await createCollage();
        if (dataUrl) {
            const blob = await (await fetch(dataUrl)).blob();
            await navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
            setSnackbarMessage("Collage Copied to clipboard!");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }
    };

    const handleImageSelect = (imageUrl) => {
        if (selectedImages.includes(imageUrl)) {
            setSelectedImages(selectedImages.filter((img) => img !== imageUrl));
        } else if (selectedImages.length < 9) {
            setSelectedImages([...selectedImages, imageUrl]);
        }
    };

    return (
        <div className="p-6 bg-gray-50 min-h-screen flex flex-col items-center">
            <h2 className="text-2xl font-bold mb-6 text-gray-700">Collage Creator</h2>

            {/* Toggle Input Mode */}
            <div className="flex items-center justify-center mb-6 space-x-4">
                <button
                    onClick={handleToggle}
                    className={`px-6 py-2 text-sm font-medium rounded-full shadow-md transition-all duration-300 ${isAsinMode ? "bg-blue-600 text-white hover:bg-blue-700" : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                        }`}
                >
                    ASIN
                </button>
                <button
                    onClick={handleToggle}
                    className={`px-6 py-2 text-sm font-medium rounded-full shadow-md transition-all duration-300 ${!isAsinMode ? "bg-blue-600 text-white hover:bg-blue-700" : "bg-gray-200 text-gray-600 hover:bg-gray-300"
                        }`}
                >
                    Link
                </button>
            </div>
            <textarea
                value={inputValue}
                onChange={handleInputChange}
                placeholder={
                    isAsinMode
                        ? "Enter item IDs separated by commas (e.g., B0D7M4M611)"
                        : "Enter full product link(s) (e.g., https://www.amazon.in/dp/B0CWYXPS59)"
                }
                className="w-full max-w-lg p-3 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows={3}
            ></textarea>

            <button
                onClick={fetchProductDetails}
                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 mb-6 flex items-center justify-center"
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={20} className="mr-2" /> : "Fetch Product"}
            </button>

            <ProductPost product={product} />

            {/* Image Selection Section */}
            <div className="mt-6 mb-6">
                <h3 className="text-xl font-semibold text-gray-700 mb-4">Select Images</h3>
                <div className={`grid grid-cols-9 gap-4`}>
                    {productImages.slice(0, 9).map((imageUrl, index) => (
                        <div
                            key={index}
                            className="relative"
                            onClick={() => handleImageSelect(imageUrl)}
                        >
                            <img
                                src={imageUrl}
                                alt={`Selectable Image ${index + 1}`}
                                className="object-contain w-20 h-20 cursor-pointer rounded-lg shadow-lg"
                            />
                            {selectedImages.includes(imageUrl) && (
                                <div className="absolute top-0 left-0 right-0 bottom-0 bg-opacity-50 bg-black rounded-lg"></div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {/* Collage Action Buttons */}
            {selectedImages.length > 0 && (
                <div className="flex justify-center space-x-6 mt-6">
                    <button
                        onClick={downloadCollage}
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                        Download Collage
                    </button>
                    <button
                        onClick={copyCollage}
                        className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                    >
                        Copy Collage
                    </button>
                </div>
            )}
            {/* Image View Section */}
            {selectedImages.length > 0 && (
                <div className="mt-6 mb-6">
                    <h3 className="text-xl font-semibold text-gray-700 mb-4 text-center">View Selected Images</h3>
                    <div
                        ref={collageRef}
                        className={`grid ${getGridClass()} p-2 gap-2`}
                        style={{
                            width: "100%",
                            maxWidth: "500px", // Adjust max width for large screens
                            margin: "0 auto", // Center the collage
                        }}
                    >
                        {selectedImages.map((imageUrl, index) => (
                            <div key={index} className="relative">
                                <img
                                    src={imageUrl}
                                    alt={`Selected Image ${index + 1}`}
                                    className="object-contain w-full h-full cursor-pointer rounded-lg shadow-lg"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}




            {/* Snackbar */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity} // Dynamic severity
                >
                    {snackbarMessage} {/* Dynamic message */}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default CollagePage;
